<template>

    <!-- sidebar -->
    <div class="z-10 premi bg-gray-100 w-full space-y-6 absolute inset-y-0 left-0 transform transition duration-200 ease-in-out" :class="premi">
      <div class="w-full h-full overflow-y-auto overflow-x-hidden relative">
        <div class="w-full bg-white px-7 py-4 rounded-bl-lg rounded-br-lg shadow flex justify-between">
          <svg @click="setPremi()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
        <div class="max-w-none mx-auto mb-14 divide-y divide-gray-200 pt-3 pb-3 sm:p-3">
          <div class="bg-white overflow-hidden shadow rounded-bl-lg rounded-br-lg" v-if="!loading">
            <div v-if="premiLength<1" class="p-4 text-center">
              Data premi <b>{{month[new Date(bulan).getMonth()]}}</b> tahun <b>{{new Date(bulan).getFullYear()}}</b> kosong
            </div>
            <ul v-else role="list" class="divide-y divide-gray-100">
              <template v-for="(data) in data_premi" :key="data.id">
                <li>
                  <a href="#" class="block hover:bg-gray-50">
                    <div class="px-4 py-4">
                      <div class="flex items-center justify-between">
                        <div class="text-sm font-medium text-indigo-600 truncate">
                          {{data.nama_premi}}
                        </div>
                        <div class="ml-2 flex-shrink-0 flex">
                          <span class="px-2 inline-flex text-xs leading-5 text-sm font-medium text-indigo-600 truncate">
                            Rp. {{data.harga}}
                          </span>
                        </div>
                      </div>
                      <div class="flex justify-between">
                        <div class="flex">
                          <div class="mr-6 flex items-center text-xs text-gray-500">
                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            {{data.tgl_premi}}
                          </div>
                        </div>
                      </div>
                      <div>
                        <small class="leading-3">{{data.keterangan}}</small>
                      </div>
                    </div>
                  </a>
                </li>
              </template>
            </ul>
            <div v-if="premiLength>0" class="bg-white sm:px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div class="hidden sm:flex-1 flex justify-between sm:hidden">
                <a href="#" class="relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Previous
                </a>
                <a href="#" class="ml-3 relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Next
                </a>
              </div>
              <div class="flex-1 sm:flex sm:items-center sm:justify-between">
                <div class="flex justify-center mb-2">
                  <p class="text-sm text-gray-700">
                    Showing
                    <span class="font-medium">{{(page-1) * limit + 1}}</span>
                    to
                    <span class="font-medium">{{((page-1) * limit) + premiLength}}</span>
                    of
                    <span class="font-medium">{{recordsTotal}}</span>
                    results
                  </p>
                </div>
                <div class="flex justify-center" v-if="maxPage!=1">
                  <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <a href="#" class="relative inline-flex items-center px-3 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                      <!-- <span class="sr-only">Previous</span> -->
                      <!-- Heroicon name: solid/chevron-left -->
                      <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                      </svg>
                    </a>
                    <template v-if="maxPage>=7">
                      <template v-if="page<=4">
                        <template v-for="n in 5" :key="'first'+n">
                          <a @click="getPremi(n)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="page==n ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                            {{n}}
                          </a>
                        </template>
                      </template>
                      <template v-else>
                        <a @click="getPremi(1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                          1
                        </a>
                        <span class="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                          ...
                        </span>
                        <span v-if="page<=maxPage-3 && page<=maxPage">
                          <a @click="getPremi(page-1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                              {{page-1}}
                            </a>
                            <a @click="getPremi(page)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium'">
                              {{page}}
                            </a>
                            <a @click="getPremi(page+1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                              {{page+1}}
                            </a>
                        </span>
                      </template>
                      <!-- <span v-if="maxPage>7" class="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span> -->
                      <template v-if="page>maxPage-3 && page<=maxPage">
                        <template v-for="n in 4" :key="'first'+n">
                          <a @click="getPremi(n+(maxPage-4))" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="page==n+(maxPage-4) ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                            {{n+(maxPage-4)}}
                          </a>
                        </template>
                      </template>
                      <template v-else>
                        <!-- <span v-if="page>4 && page>maxPage-4">
                          <a @click="getPremi(page-1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                              {{page-1}}
                            </a>
                            <a @click="getPremi(page)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium'">
                              {{page}}
                            </a>
                            <a @click="getPremi(page+1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                              {{page+1}}
                            </a>
                        </span> -->
                        <span class="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                          ...
                        </span>
                        <a @click="getPremi(maxPage)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                          {{maxPage}}
                        </a>
                      </template>
                    </template>
                    <template v-else-if="maxPage!=1">
                      <a v-for="n in maxPage" :key="'third'+n" @click="getPremi(n)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="page==n ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                        {{n}}
                      </a>
                    </template>
                    <a href="#" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                      <!-- <span class="sr-only">Next</span> -->
                      <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>

export default {
  name: 'Premi',
  data () {
    return {
      premi: '-translate-x-full',
      data_premi: [],
      limit: 10,
      recordsTotal: 0,
      page: 1,
      loading: false,
      month: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    }
  },
  props: {
    bulan: String
  },
  computed: {
    isActive () {
      return this.premi !== ''
    },
    token () {
      return this.$store.state.token
    },
    premiLength () {
      return this.data_premi.length
    },
    maxPage () {
      return Math.ceil(this.recordsTotal / this.limit)
    }
  },
  methods: {
    setPremi () {
      if (this.premi === '') {
        this.premi = '-translate-x-full'
        this.data_premi = []
        this.limit = 10
        this.recordsTotal = 0
        this.page = 1
      } else {
        this.premi = ''
        this.getPremi()
      }
    },
    getPremi (page = 1) {
      this.loading = true
      this.page = page
      var request = new FormData()
      request.append('start', (page - 1) * this.limit)
      request.append('length', this.limit)
      request.append('bulan', this.bulan)
      this.axios.post('laporan_pekerjaan/get_premi_by_user?token=' + this.token, request).then(response => {
        this.data_premi = response.data.data
        this.recordsTotal = response.data.recordsTotal
        this.loading = false
      }).catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('X-FPRINT-TOKEN', null)
          this.$router.push({ name: 'Login' })
        }
      })
    }
  }
}
</script>
