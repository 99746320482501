<template>
  <div class="w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative">
    <sidebar ref="sidebar"></sidebar>
    <premi :bulan="bulan" ref="premi"></premi>
    <div class="w-full h-full overflow-y-auto overflow-x-hidden bg-gray-100 relative">
      <div class="w-full bg-white px-7 py-4 rounded-bl-lg rounded-br-lg shadow flex justify-between">
        <svg @click="$refs.sidebar.setSidebar()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <!-- <svg @click="logout" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg> -->
      </div>
      <div class="max-w-none mx-auto mb-14 divide-y divide-gray-200 pt-3 pb-3 sm:p-3">
        <div class="shadow rounded-tl-lg rounded-tr-lg p-4 bg-white flex flex-col gap-y-2">
          <label class="font-semibold">Gaji bulan</label>
          <input v-model="bulan" type="month" class="border-2 py-1 px-2 rounded-md">
        </div>
        <template v-if="!loading">
          <div class="bg-white overflow-hidden shadow rounded-bl-lg rounded-br-lg  p-4 text-center" v-if="gaji_kotor==0 && premi==0 && gaji_absen==0">
            Gaji bulan <b>{{month[new Date(bulan).getMonth()]}}</b> tahun <b>{{new Date(bulan).getFullYear()}}</b> kosong
          </div>
          <div v-else class="mt-4 divide-y divide-gray-200">
            <div class="bg-white px-4 py-4">
              <h2 class="font-semibold">Gaji Kotor</h2>
              Rp. {{gaji_kotor}}
            </div>
            <div class="bg-white px-4 py-4">
              <h2 class="font-semibold">Gaji Absen</h2>
              Rp. {{gaji_absen}}
            </div>
            <div class="bg-white px-4 py-4 flex justify-between items-center">
              <div>
                <h2 class="font-semibold">Premi</h2>
                <span class="text-red-500">-Rp. {{premi}}</span>
              </div>
              <svg @click="$refs.premi.setPremi()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
            <div class="bg-white px-4 py-4 rounded-bl-lg rounded-br-lg">
              <h2 class="font-semibold">Gaji Bersih</h2>
              <span class="text-green-500">Rp. {{gaji_bersih}}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Premi from '../components/Premi.vue'
import Sidebar from '../components/Sidebar.vue'

export default {
  name: 'Home',
  components: { Premi, Sidebar },
  data () {
    return {
      bulan: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2),
      gaji_kotor: 0,
      premi: 0,
      gaji_bersih: 0,
      gaji_absen: 0,
      loading: false,
      month: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    }
  },
  computed: {
    token () {
      return this.$store.state.token
    }
  },
  watch: {
    // whenever question changes, this function will run
    bulan () {
      this.getGaji()
    }
  },
  created () {
    // this.getLaporan(1)
    this.getGaji()
  },
  methods: {
    getGaji () {
      this.loading = true
      var request = new FormData()
      request.append('bulan', this.bulan)
      this.axios.post('laporan_pekerjaan/get_gaji_by_user?token=' + this.token, request).then(response => {
        this.gaji_kotor = response.data.gaji_kotor
        this.gaji_absen = response.data.gaji_absen
        this.premi = response.data.premi
        this.gaji_bersih = response.data.gaji_bersih
        this.loading = false
      }).catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('X-FPRINT-TOKEN', null)
          this.$router.push({ name: 'Login' })
        }
      })
    }
  }
}
</script>
