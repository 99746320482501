<template>
  <div class="container">
    <div class="w-screen h-screen flex flex-col items-center py-6">
      <div class="z-10 fixed left-0 w-full pl-3 pr-7">
        <div v-if="alertFailed" class="w-full rounded-lg p-4 flex justify-between bg-red-300 text-white">
          <span>Gagal Login</span>
          <svg @click="alertFailed=false" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>
      <form @submit.prevent="login" class="w-screen h-screen flex flex-col justify-center items-center p-4 gap-y-3">
        <h1 class="font-bold text-gray-700 text-3xl">Log In</h1>
        <img src="../assets/logo.jpeg" alt="">
        <div class="w-full space-y-1">
          <label class="block px-3 font-semibold">Username</label>
          <input v-model="username" class="w-full py-2 px-3 border-2 rounded-md" type="text" placeholder="username"/>
        </div>
        <div class="w-full space-y-1">
          <label class="block px-3 font-semibold">Password</label>
          <input v-model="password" class="w-full py-2 px-3 border-2 rounded-md" type="password" placeholder="password"/>
        </div>
        <button type="submit" class="w-full flex justify-center mt-1 py-2 px-3 rounded-md bg-blue-500 text-white text-lg hover:bg-blue-600 duration-300">
          <div v-if="loading" class="animate-spin rounded-full w-8 h-8 border-b-2 border-gray-900"></div>
          <template v-else>Login</template>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  data () {
    return {
      username: '',
      password: '',
      alertFailed: false,
      loading: false
    }
  },
  methods: {
    login () {
      this.loading = true
      var request = new FormData()
      request.append('username', this.username)
      request.append('password', this.password)
      this.axios.post('login/api', request).then(response => {
        if (response.data.status === 'OK') {
          localStorage.setItem('X-FPRINT-TOKEN', response.data.token)
          if (localStorage.getItem('X-FPRINT-TOKEN')) {
            this.loading = false
            this.$store.dispatch('set_token', response.data.token)
            this.$router.push({ name: 'Home' })
          }
        } else {
          this.loading = false
          this.alertFailed = true
          setTimeout(() => {
            this.alertFailed = false
          }, 7000)
        }
      }).catch((e) => {
        this.loading = false
        this.alertFailed = true
        setTimeout(() => {
          this.alertFailed = false
        }, 7000)
      })
    }
  }
}
</script>
