import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '../views/Login.vue'
import Home from '../views/Home.vue'
import Create from '../views/Create.vue'
import Edit from '../views/Edit.vue'
import Gaji from '../views/Gaji.vue'
import Absen from '../views/Absen.vue'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/create',
    name: 'Create',
    component: Create
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/gaji',
    name: 'Gaji',
    component: Gaji
  },
  {
    path: '/absen',
    name: 'Absen',
    component: Absen
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    if (store.state.token !== null) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    if (store.state.token !== null) {
      next({ name: 'Home' })
    } else {
      next()
    }
  }
})

export default router
