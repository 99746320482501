<template>
  <div class="w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative">
    <div class="z-10 fixed left-0 w-full pl-3 pr-3">
      <div v-if="alertSuccess" class="w-full rounded-lg p-4 flex justify-between bg-green-300 text-white">
        <span>Berhasil membuat laporan</span>
        <svg @click="alertSuccess=false" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="z-10 fixed left-0 w-full pl-3 pr-3">
      <div v-if="alertFailed" class="w-full rounded-lg p-4 flex justify-between bg-red-300 text-white">
        <span>Gagal membuat laporan</span>
        <svg @click="alertFailed=false" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="w-full bg-white px-7 py-4 rounded-bl-lg rounded-br-lg shadow flex justify-between">
      <svg @click="$router.push({ name:'Home' })" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 hover:text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
    </div>
    <div class="w-full mt-3">
      <div class="w-full bg-white rounded-lg p-4">
        <form class="flex flex-col gap-y-5" @submit.prevent="submit">
          <div class="flex flex-col gap-1">
            <label>Nama pekerjaan <span class="text-red-500">*</span></label>
            <input v-model="nama_pekerjaan" type="text" class="border-2 border-gray-300 rounded-lg px-2 py-2 focus:outline-none focus:border-gray-400" placeholder="Tulis nama pekerjaan">
          </div>
          <div class="flex flex-col gap-1">
            <label>Jumlah <span class="text-red-500">*</span></label>
            <input v-model="jumlah" type="number" class="border-2 border-gray-300 rounded-lg px-2 py-2 focus:outline-none focus:border-gray-400" placeholder="Tulis jumlah yang dikerjakan">
          </div>
          <div class="flex flex-col gap-1">
            <label>Foto <span class="text-red-500">*</span></label>
            <div class="w-full h-10 border-2 border-dashed flex justify-center items-center" @click="$refs.file.click()" v-if='file==null'>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
              </svg>
            </div>
            <div v-else class="block relative w-full h-auto" @click="$refs.file.click()">
              <img class="" :src="preview" />
              <div class="absolute w-full h-full bg-black top-0 opacity-0 duration-300 hover:opacity-60 flex justify-center items-center cursor-pointer">
                <span class="text-white text-3xl">Ganti</span>
              </div>
            </div>
            <input class="hidden" ref="file" @change="input" type="file">
          </div>
          <div class="flex flex-col gap-1">
            <label>Keterangan <span class="text-red-500">*</span></label>
            <textarea v-model="keterangan" class="border-2 border-gray-300 rounded-lg px-2 py-2 focus:outline-none focus:border-gray-400" rows="8" placeholder="Tulis keterangan pekerjaan, jumlah yang dikerjakan"></textarea>
          </div>
          <button type="submit" :disabled="loading || nama_pekerjaan.trim()=='' || keterangan.trim()=='' || jumlah=='' || jumlah<1 || file == null" class="w-full bg-purple-500 not:disabled:hover:bg-purple-600 disabled:opacity-70 duration-150 text-white px-2 py-3 rounded-lg flex align-center justify-center">
            <span v-if="!loading">Submit</span>
            <img v-else class="w-8 h-6" src="../assets/loading.gif" alt="">
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Create',
  data () {
    return {
      nama_pekerjaan: '',
      keterangan: '',
      jumlah: 1,
      file: null,
      preview: null,
      alertSuccess: false,
      alertFailed: false,
      loading: false
    }
  },
  computed: {
    token () {
      return this.$store.state.token
    }
  },
  methods: {
    input (self) {
      if (self.target.files.length > 0) {
        this.file = self.target.files[0]
        this.preview = URL.createObjectURL(this.file)
      }
    },
    reset () {
      this.nama_pekerjaan = ''
      this.jumlah = 1
      this.keterangan = ''
      this.$refs.file.value = ''
      this.file = null
      this.preview = null
      this.loading = false
    },
    submit () {
      console.log('tes')
      this.alertSuccess = false
      this.alertFailed = false
      this.loading = true
      if (this.nama_pekerjaan !== '' && this.keterangan !== '' && this.file !== '') {
        var formData = new FormData()
        formData.append('nama_pekerjaan', this.nama_pekerjaan)
        formData.append('keterangan', this.keterangan)
        formData.append('jumlah', this.jumlah)
        formData.append('file', this.file)
        this.axios.post('laporan_pekerjaan/create?token=' + this.token, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response.data.status === 'OK') {
            this.alertSuccess = true
            setTimeout(() => {
              this.alertSuccess = false
              this.reset()
              this.$router.push({ name: 'Home' })
            }, 700)
          } else {
            this.alertFailed = true
            setTimeout(() => {
              this.alertFailed = false
              this.reset()
            }, 7000)
          }
        }).catch(e => {
          if (e.response.status === 401) {
            localStorage.removeItem('X-FPRINT-TOKEN', null)
            this.$router.push({ name: 'Login' })
          } else {
            this.alertFailed = true
            setTimeout(() => {
              this.alertFailed = false
              this.reset()
            }, 7000)
          }
        })
      } else {
        this.alertFailed = true
        setTimeout(() => {
          this.alertFailed = false
          this.reset()
        }, 7000)
      }
    }
  }
}
</script>
