<template>

    <!-- sidebar -->
    <div class="z-20 sidebar bg-blue-800 text-blue-100 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform transition duration-200 ease-in-out" :class="sidebar">
    <!-- <div :class="sidebar" class=""> -->

      <!-- logo -->
      <a @click="setSidebar()" class="text-white flex items-center space-x-2 px-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
        <!-- <span class="text-2xl font-extrabold">Better Dev</span> -->
      </a>

      <!-- nav -->
      <nav>
        <!-- <router-link :to="{name: 'Home'}" class="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white" active-class="bg-blue-700 text-white">
          Home
        </router-link> -->
        <router-link :to="{name: 'Home'}" class="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white" active-class="bg-blue-600 text-white">
          Home
        </router-link>
        <router-link :to="{name: 'Gaji'}" class="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white" active-class="bg-blue-600 text-white">
          Gaji & Premi
        </router-link>
        <router-link :to="{name: 'Absen'}" class="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white" active-class="bg-blue-600 text-white">
          Absen
        </router-link>
      </nav>
    </div>
</template>

<script>

export default {
  name: 'Sidebar',
  data () {
    return {
      sidebar: '-translate-x-full'
    }
  },
  computed: {
    isActive () {
      return this.sidebar !== ''
    }
  },
  methods: {
    setSidebar () {
      if (this.sidebar === '') {
        this.sidebar = '-translate-x-full'
      } else {
        this.sidebar = ''
      }
    }
  }
}
</script>
