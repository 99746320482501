<template>
  <div class="w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative">
    <sidebar ref="sidebar"></sidebar>
    <div class="w-full h-full overflow-y-auto overflow-x-hidden bg-gray-100 relative">
      <div class="z-10 fixed left-0 w-full">
        <div v-if="alertSuccess" class="w-full rounded-lg p-4 flex justify-between bg-green-300 text-white">
          <span>Berhasil menghapus laporan</span>
          <svg @click="alertSuccess=false" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>
      <div class="z-10 fixed left-0 w-full">
        <div v-if="alertFailed" class="w-full rounded-lg p-4 flex justify-between bg-red-300 text-white">
          <span>Gagal menghapus laporan</span>
          <svg @click="alertFailed=false" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      </div>
      <div class="w-full bg-white px-7 py-4 rounded-bl-lg rounded-br-lg shadow flex justify-between">
        <svg @click="$refs.sidebar.setSidebar()" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <svg @click="logout" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
      </div>
      <div class="max-w-none mx-auto mb-14 divide-y divide-gray-200 pt-3 pb-3 sm:p-3">
        <div class="shadow rounded-tl-lg rounded-tr-lg p-4 bg-white flex flex-col gap-y-2">
          <label class="font-semibold">Data bulan</label>
          <input v-model="bulan" type="month" class="border-2 py-1 px-2 rounded-md">
        </div>
        <div class="bg-white overflow-hidden shadow rounded-bl-lg rounded-br-lg">
          <div v-if="laporan.length<1" class="p-4 text-center">
            Data bulan <b>{{month[new Date(bulan).getMonth()]}}</b> tahun <b>{{new Date(bulan).getFullYear()}}</b> kosong
          </div>
          <ul v-else role="list" class="divide-y divide-gray-100">
            <template v-for="(data, key) in laporan" :key="data.id">
              <li>
                <a href="#" class="block hover:bg-gray-50" @click="laporan[key].active = !laporan[key].active">
                  <div class="px-4 py-4">
                    <div class="flex items-center justify-between">
                      <div class="text-sm font-medium text-indigo-600 truncate">
                        {{data.nama_pekerjaan}}
                      </div>
                      <div class="ml-2 flex-shrink-0 flex">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="data.verified==1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'">
                          {{data.verified==1 ? 'Verified' : 'Unverified'}}
                        </span>
                      </div>
                    </div>
                    <div class="mt-2 flex justify-between">
                      <div class="flex">
                        <div class="mr-6 flex items-center text-sm text-gray-500">
                          <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          {{data.tgl_laporan}}
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-3 flex items-center text-sm text-white bg-indigo-600 w-8 h-8 justify-center rounded-full">
                          {{data.jumlah}}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="bg-gray-50">
                <div class="px-4 py-4 flex flex-col gap-2" v-if="data.active">
                  <div class="relative w-38 h-0 pb-2/3 pt-1/3">
                    <img class="absolute inset-0 w-full h-full object-cover shadow-lg rounded-lg" :src="data.gambar" />
                  </div>
                  <div class="flex flex-col justify-start overflow-x-hidden">
                    <span class="truncate text-black font-semibold">{{data.nama_pekerjaan}}</span>
                    <!-- <span class="truncate" v-if="data.nama_kategori   !=null">{{data.nama_kategori}}</span> -->
                    <span class="truncate text-green-400" v-if="data.verified!=0">Rp{{data.harga_akhir}}</span>
                    <span class="truncate text-green-400" v-else>{{data.harga_akhir}}</span>
                    <span class="truncate mt-2 text-gray-500">{{data.keterangan}}</span>
                  </div>
                  <div v-if="data.verified==0" class="flex justify-end gap-2">
                    <svg  xmlns="http://www.w3.org/2000/svg" @click="$router.push({ name: 'Edit', params: { id: data.id } })" class="w-12 h-12 hover:bg-indigo-600 hover:text-white duration-300 py-1 px-3 rounded-lg stroke-current text-indigo-600 truncate cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                    <svg @click="deleteAction(data.id, key)" xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 hover:bg-red-600 hover:text-white duration-300 py-1 px-3 rounded-lg text-md font-medium stroke-current text-red-600 truncate" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <div v-if="laporan.length>0" class="bg-white sm:px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div class="hidden sm:flex-1 flex justify-between sm:hidden">
              <a href="#" class="relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Previous
              </a>
              <a href="#" class="ml-3 relative inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Next
              </a>
            </div>
            <div class="flex-1 sm:flex sm:items-center sm:justify-between">
              <div class="flex justify-center mb-2">
                <p class="text-sm text-gray-700">
                  Showing
                  <span class="font-medium">{{(page-1) * limit + 1}}</span>
                  to
                  <span class="font-medium">{{((page-1) * limit) + laporanLength}}</span>
                  of
                  <span class="font-medium">{{recordsTotal}}</span>
                  results
                </p>
              </div>
              <div class="flex justify-center" v-if="maxPage!=1">
                <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <a href="#" class="relative inline-flex items-center px-3 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <!-- <span class="sr-only">Previous</span> -->
                    <!-- Heroicon name: solid/chevron-left -->
                    <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                  </a>
                  <template v-if="maxPage>=7">
                    <template v-if="page<=4">
                      <template v-for="n in 5" :key="'first'+n">
                        <a @click="getLaporan(n)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="page==n ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                          {{n}}
                        </a>
                      </template>
                    </template>
                    <template v-else>
                      <a @click="getLaporan(1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                        1
                      </a>
                      <span class="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>
                      <span v-if="page<=maxPage-3 && page<=maxPage">
                        <a @click="getLaporan(page-1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                            {{page-1}}
                          </a>
                          <a @click="getLaporan(page)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium'">
                            {{page}}
                          </a>
                          <a @click="getLaporan(page+1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                            {{page+1}}
                          </a>
                      </span>
                    </template>
                    <!-- <span v-if="maxPage>7" class="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                      ...
                    </span> -->
                    <template v-if="page>maxPage-3 && page<=maxPage">
                      <template v-for="n in 4" :key="'first'+n">
                        <a @click="getLaporan(n+(maxPage-4))" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="page==n+(maxPage-4) ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                          {{n+(maxPage-4)}}
                        </a>
                      </template>
                    </template>
                    <template v-else>
                      <!-- <span v-if="page>4 && page>maxPage-4">
                        <a @click="getLaporan(page-1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                            {{page-1}}
                          </a>
                          <a @click="getLaporan(page)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium'">
                            {{page}}
                          </a>
                          <a @click="getLaporan(page+1)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                            {{page+1}}
                          </a>
                      </span> -->
                      <span class="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                        ...
                      </span>
                      <a @click="getLaporan(maxPage)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                        {{maxPage}}
                      </a>
                    </template>
                  </template>
                  <template v-else-if="maxPage!=1">
                    <a v-for="n in maxPage" :key="'third'+n" @click="getLaporan(n)" href="#" class="relative inline-flex items-center px-3 py-1 border text-sm font-medium" :class="page==n ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-3 py-1 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'">
                      {{n}}
                    </a>
                  </template>
                  <a href="#" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <!-- <span class="sr-only">Next</span> -->
                    <svg class="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed bottom-5 right-5">
        <router-link class="flex h-10 w-10 relative" :to="{name:'Create'}">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
          <span class="relative inline-flex h-10 w-10 rounded-full bg-purple-500 hover:bg-purple-600 flex items-center justify-center text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'

export default {
  name: 'Home',
  components: { Sidebar },
  data () {
    return {
      alertSuccess: false,
      alertFailed: false,
      bulan: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2),
      laporan: [],
      gaji: 0,
      limit: 10,
      recordsTotal: 0,
      page: 1,
      month: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    }
  },
  computed: {
    token () {
      return this.$store.state.token
    },
    laporanLength () {
      return this.laporan.length
    },
    maxPage () {
      return Math.ceil(this.recordsTotal / this.limit)
    }
  },
  watch: {
    // whenever question changes, this function will run
    bulan () {
      this.getLaporan(1)
      this.getGaji()
    }
  },
  created () {
    this.getLaporan(1)
    this.getGaji()
  },
  methods: {
    getLaporan (page) {
      this.page = page
      var request = new FormData()
      request.append('start', (page - 1) * this.limit)
      request.append('length', this.limit)
      request.append('bulan', this.bulan)
      this.axios.post('laporan_pekerjaan/get_by_user?token=' + this.token, request).then(response => {
        var data = response.data.data.map(r => {
          r.active = false
          return r
        })
        this.laporan = data
        this.recordsTotal = response.data.recordsTotal
      }).catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('X-FPRINT-TOKEN', null)
          this.$router.push({ name: 'Login' })
        }
      })
    },
    getGaji () {
      var request = new FormData()
      request.append('bulan', this.bulan)
      this.axios.post('laporan_pekerjaan/get_gaji_by_user?token=' + this.token, request).then(response => {
        this.gaji = response.data
      }).catch(e => {
        if (e.response.status === 401) {
          localStorage.removeItem('X-FPRINT-TOKEN', null)
          this.$router.push({ name: 'Login' })
        }
      })
    },
    deleteAction (id, key) {
      var sure = confirm('Anda yakin menghapus laporan ini')
      if (sure) {
        this.axios.post('laporan_pekerjaan/delete/' + id + '?token=' + this.token).then(response => {
          if (response.data.status === 'OK') {
            this.laporan.splice(key, 1)
            this.recordsTotal--
            this.alertSuccess = true
            setTimeout(() => {
              this.alertSuccess = false
            }, 7000)
          } else {
            this.alertFailed = true
            setTimeout(() => {
              this.alertFailed = false
            }, 7000)
          }
        }).catch(e => {
          if (e.response.status === 401) {
            localStorage.removeItem('X-FPRINT-TOKEN', null)
            this.$router.push({ name: 'Login' })
          } else {
            this.alertFailed = true
            setTimeout(() => {
              this.alertFailed = false
            }, 7000)
          }
        })
      }
    },
    logout () {
      localStorage.removeItem('X-FPRINT-TOKEN', null)
      this.$store.dispatch('set_token', null)
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
