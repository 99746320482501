<template>
  <div class="w-screen h-screen overflow-y-auto overflow-x-hidden bg-gray-100 relative">
    <div class="w-screen h-8 bg-red-500 flex items-center justify-center text-white text-xs" :class="in_range ? 'max-h-0' : 'max-h-8'">Sedang Tidak Dilokasi</div>
    <sidebar ref="sidebar"></sidebar>
    <div class="w-full h-full overflow-y-auto overflow-x-hidden relative flex flex-col bg-white">
      <svg class="absolute w-60 top-6" width="579" height="213" viewBox="0 0 579 213" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="15" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="15" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="15" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="259" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="259" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="259" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="259" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="137" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="137" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="137" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="137" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="381" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="503" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="381" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="503" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="381" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="503" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="381" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="503" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="76" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="76" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="76" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="76" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="320" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="320" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="320" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="320" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="198" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="198" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="198" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="198" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="442" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="564" cy="15" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="442" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="564" cy="137" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="442" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="564" cy="76" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="442" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
        <circle cx="564" cy="198" r="15" fill="#C4C4C4" fill-opacity="0.27"/>
      </svg>
      <div class="w-full bg-white px-7 py-4 rounded-bl-lg rounded-br-lg shadow flex justify-between">
        <svg @click="$refs.sidebar.setSidebar()" xmlns="http://www.w3.org/2000/svg" class="z-10 h-6 w-6 stroke-current text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <div class="relative">
          <div class="border-gray-200 border-2 rounded-md">
            <svg @click="clickInfo = !clickInfo" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div v-if="clickInfo" class="absolute right-0 mt-2 pl-4 py-2 w-36 bg-white rounded-md shadow-xl border-gray-200 border-2 rounded-md">
            <h2 class="text-gray-700 text-sm">Absen dibuka:</h2>
            <div class="divide-y divide-gray-100">
              <div v-for="(data, index) in waktu_aktif" :key="index" class="text-gray-600 text-xs py-2">{{zeroPadding(new Date(data.mulai).getHours(), 2)}}:{{zeroPadding(new Date(data.mulai).getMinutes(), 2)}} - {{zeroPadding(new Date(data.akhir).getHours(), 2)}}:{{zeroPadding(new Date(data.akhir).getMinutes(), 2)}}</div>
            </div>
          </div>
        </div>
        <!-- <svg @click="logout" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 stroke-current text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg> -->
      </div>
      <div class="content h-full flex flex-col justify-center items-center">
        <div id="clock" class="flex flex-col items-center justify-center">
            <h1 class="time text-4xl font-semibold text-gray-700 ">{{ time }}</h1>
            <p class="date text-xl text-gray-400 font-semibold">{{ date }}</p>
        </div>
        <button v-if="button&&!loading_absen" @click="have_absen ? modalConfirm=true : absen()" type="button" class="mb-4 mt-10 w-44 h-44 rounded-full bg-gradient-to-tr from-blue-400 via-purple-500 to-pink-500 rounded-lg flex flex-col items-center justify-center active:ring-4 active:ring-purple-300 transition duration-100 disabled:opacity-50">
            <img src="/img/touch.png" class="animate-pulse h-16 w-16 mb-2" alt="">
            <span class="text-gray-200 font-regular text-sm">
              {{have_absen ? 'ISTIRAHAT' : 'ABSEN'}}
            </span>
        </button>
        <button v-else type="button" class="mb-4 mt-10 w-44 h-44 rounded-full bg-gray-300 rounded-lg flex flex-col items-center justify-center cursor-not-allowed" disabled>
            <img src="/img/touch.png" class="h-16 w-16 mb-2" alt="">
            <span class="text-gray-200 font-regular text-sm">
              {{have_absen ? 'ISTIRAHAT' : !loading_absen ? 'ABSEN' : "LOADING ..."}}
            </span>
        </button>
        <div class="mb-20">
          <!-- <h2 class="text-2xl font-semibold text-blue-400" v-if="!button && jam_masuk==null">Belum waktu absen</h2>
          <h2 class="text-2xl font-semibold text-green-400" v-if="!button && jam_masuk!=null && !have_absen">Sudah absen</h2>
          <h2 class="text-2xl font-semibold text-green-400" v-if="!button && jam_masuk!=null && have_absen">Sudah istirahat</h2>
          <h2 class="text-2xl font-semibold text-red-400" v-if="button && jam_masuk==null">Segera absen</h2> -->
        </div>
        <div class="flex justify-around w-full">
          <div class="flex flex-col items-center justify-center">
            <img class="w-10 h-10" src="/img/timein.png" alt="">
            <div class="mt-2 flex flex-col items-center justify-center">
              <h2 class="text-xl font-semibold text-green-400">{{ jam_masuk!=null ? zeroPadding(jam_masuk.getHours() > 12 ? jam_masuk.getHours() - 12 : jam_masuk.getHours(), 2) + ':' + zeroPadding(jam_masuk.getMinutes(), 2) + ':' + zeroPadding(jam_masuk.getSeconds(), 2) : '--:--:--' }}</h2>
              <p class="text-xs font-semibold text-gray-300">Jam Masuk</p>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center">
            <img class="w-10 h-10" src="/img/timeout.png" alt="">
            <div class="mt-2 flex flex-col items-center justify-center">
              <h2 class="text-xl font-semibold text-red-400">{{ jam_istirahat!=null ? zeroPadding(jam_istirahat.getHours() > 12 ? jam_istirahat.getHours() - 12 : jam_istirahat.getHours(), 2) + ':' + zeroPadding(jam_istirahat.getMinutes(), 2) + ':' + zeroPadding(jam_istirahat.getSeconds(), 2) : '--:--:-- '}}</h2>
              <p class="text-xs font-semibold text-gray-300">Jam Istirahat</p>
            </div>
          </div>
          <div class="flex flex-col items-center justify-center">
            <img class="w-10 h-10" src="/img/clock.png" alt="">
            <div class="mt-2 flex flex-col items-center justify-center">
              <h2 class="text-xl font-semibold text-gray-400">{{jam_kerja}}</h2>
              <p class="text-xs font-semibold text-gray-300">Waktu Kerja</p>
            </div>
          </div>
          <!-- <img class="w-10 h-10" src="/img/timein.svg" alt="">
          <img class="w-10 h-10" src="/img/timein.svg" alt=""> -->
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="modalConfirm" class="z-20 mx-auto w-full h-full fixed inset-0 flex items-center justify-center">
        <div class="backdrop absolute w-full h-full bg-black opacity-50" @click="modalConfirm=false"></div>
        <div class="z-30 bg-white w-3/4 h-56 flex flex-col justify-between p-4">
          <div class="flex items-center justify-center h-full w-full">
            <h2 class="text-lg text-center font-semibold">Klik tombol Istirahat jika ingin melanjutkan!</h2>
          </div>
          <div class="flex justify-end gap-2">
            <button class="bg-red-400 px-4 py-1 text-white" @click="modalConfirm=false">Batal</button>
            <button class="bg-green-400 px-4 py-1 text-white" @click="absen">Istirahat</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'

export default {
  name: 'Absen',
  components: { Sidebar },
  data () {
    return {
      time: '',
      date: '',
      week: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Ju\'mat', 'Sabtu'],
      month: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      clock: null,
      button: false,
      have_absen: false,
      in_range: true,
      jam_masuk: null,
      jam_istirahat: null,
      waktu_aktif: null,
      clickInfo: false,
      now: null,
      modalConfirm: false,
      position: null,
      loading_absen: false
    }
  },
  computed: {
    token () {
      return this.$store.state.token
    },
    jam_kerja () {
      if (this.jam_masuk !== null && this.now !== null && !this.have_absen) {
        var diff = (this.now.getTime() - this.jam_masuk.getTime()) / 1000 / 60 / 60
        var jam = Math.floor(diff)
        var menit = Math.floor((diff - jam) * 60)
        var detik = Math.floor((((diff - jam) * 60) - (menit)) * 60)
        return this.zeroPadding(jam, 2) + ':' + this.zeroPadding(menit, 2) + ':' + this.zeroPadding(detik, 2)
      } else {
        return '--:--:--'
      }
    }
  },
  mounted () {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(this.success, this.error)
    } else {
      alert('Perangkat tidak compatible')
      this.in_range = false
    }
    this.updateTime()
    this.clock = setInterval(() => {
      this.updateTime()
      this.checkWaktuAktif()
      this.timeOut()
    }, 1000)
    this.getAbsenActive()
  },
  methods: {
    success (position) {
      this.position = position
      this.axios.get('e_kinerja/absen_user/in_range?lat=' + position.coords.latitude + '&lng=' + position.coords.longitude + '&token=' + this.token).then(response => {
        this.in_range = response.data.data
      })
    },
    error (error) {
      const { code } = error
      switch (code) {
        case 1:
          console.log('permission denied')
          break
        case 2:
          console.log('position unavailable')
          break
      }
    },
    updateTime () {
      var cd = new Date()
      this.now = cd
      this.time = this.zeroPadding(cd.getHours() > 12 ? cd.getHours() - 12 : cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2)
      this.date = this.week[cd.getDay()] + ', ' + this.month[cd.getMonth()] + ' ' + this.zeroPadding(cd.getDate(), 2)
    },
    checkWaktuAktif () {
      var now = new Date()
      if (this.waktu_aktif != null) {
        var tes = this.waktu_aktif.some(p => {
          var mulai = new Date(p.mulai)
          var akhir = new Date(p.akhir)
          return now >= mulai && now <= akhir
        })
        if (tes) {
          if (this.jam_masuk == null) {
            if (this.in_range) {
              this.button = true
            } else {
              this.button = false
            }
            this.have_absen = false
          } else {
            if (this.jam_istirahat == null) {
              if (this.in_range) {
                this.button = true
              } else {
                this.button = false
              }
              this.have_absen = true
            } else {
              this.button = false
              this.have_absen = true
            }
          }
        } else {
          this.button = false
          this.have_absen = false
          this.jam_masuk = null
          this.jam_istirahat = null
        }
      }
    },
    timeOut () {
      // if (this.jam_istirahat !== null) {
      //   var now = new Date()
      //   var istirahat = new Date(this.jam_istirahat)
      //   if (now > istirahat) {
      //     this.jam_masuk = null
      //     this.jam_istirahat = null
      //     this.jam_kerja = null
      //     this.button = false
      //     this.have_absen = false
      //   }
      // }
    },
    zeroPadding (num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    },
    getAbsenActive () {
      if (navigator.geolocation) {
        if (this.position != null) {
          this.axios.get('e_kinerja/absen_user/absen_active?lat=' + this.position.coords.latitude + '&lng=' + this.position.coords.longitude + '&token=' + this.token).then(response => {
            if (response.data.data.jam_masuk !== null) {
              this.jam_masuk = new Date(response.data.data.jam_masuk)
            }
            if (response.data.data.jam_istirahat !== null) {
              this.jam_istirahat = new Date(response.data.data.jam_istirahat)
            }
            this.in_range = response.data.data.in_range
            if (this.in_range) {
              this.button = response.data.data.can_absen
            } else {
              this.button = false
            }
            this.have_absen = response.data.data.have_absen
            this.waktu_aktif = response.data.data.waktu_aktif
            this.loading_absen = false
          }).catch(e => {
            this.loading_absen = false
            if (e.response.status === 401) {
              localStorage.removeItem('X-FPRINT-TOKEN', null)
              this.$router.push({ name: 'Login' })
            }
          })
        } else {
          navigator.geolocation.getCurrentPosition(position => {
            this.position = position
            this.axios.get('e_kinerja/absen_user/absen_active?lat=' + position.coords.latitude + '&lng=' + position.coords.longitude + '&token=' + this.token).then(response => {
              if (response.data.data.jam_masuk !== null) {
                this.jam_masuk = new Date(response.data.data.jam_masuk)
              }
              if (response.data.data.jam_istirahat !== null) {
                this.jam_istirahat = new Date(response.data.data.jam_istirahat)
              }
              this.in_range = response.data.data.in_range
              if (this.in_range) {
                this.button = response.data.data.can_absen
              } else {
                this.button = false
              }
              this.have_absen = response.data.data.have_absen
              this.waktu_aktif = response.data.data.waktu_aktif
              this.loading_absen = false
            }).catch(e => {
              this.loading_absen = false
              if (e.response.status === 401) {
                localStorage.removeItem('X-FPRINT-TOKEN', null)
                this.$router.push({ name: 'Login' })
              }
            })
          })
        }
      } else {
        alert('Perangkat tidak compatible')
        this.in_range = false
      }
    },
    absen () {
      this.loading_absen = true
      if (this.position != null) {
        this.axios.post('e_kinerja/absen_user/insert_absen?lat=' + this.position.coords.latitude + '&lng=' + this.position.coords.longitude + '&token=' + this.token).then(response => {
          if (response.data.status === 'OK') {
            this.getAbsenActive()
            this.modalConfirm = false
          }
        }).catch(e => {
          this.loading_absen = false
          if (e.response.status === 401) {
            localStorage.removeItem('X-FPRINT-TOKEN', null)
            this.$router.push({ name: 'Login' })
          }
        })
      }
    }
  }
}
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
